<template>
<div class="p-8">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: "Content"
}
</script>
