<template>
  <div
    class="play-button w-22 h-22 rounded-full bg-primary flex items-center justify-center"
  >
    <i class="fas fa-play text-2xl text-white ml-1 scale"></i>
  </div>
</template>

<script>
export default {
  name: "PlayButton",
};
</script>