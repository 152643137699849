const BOTH = 0;
const AUCTION = 1;
const SALE = 2;


module.exports = Object.freeze({
    BOTH,
    AUCTION,
    SALE,
});
