<template>
  <div class="tag p-3px px-5px text-10px rounded-2sm tracking-wider bg-opacity-30 hover:bg-opacity-100">
    <slot></slot>
  </div>
</template>


<script>
export default {
  name: "Tag",
};
</script>
